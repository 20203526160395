<template>
    <div class="sidebar-container">
        <el-menu
                :default-active="defaultActive"
                class="el-menu-vertical-left"
                @open="handleOpen"
                @close="handleClose"
                @select="handleSelect"
                :unique-opened="true"
                background-color="#20222A"
                text-color="#fff"
                active-text-color="#fff">

            <el-submenu :index="item.code" v-for="(item,index) in menuList" :key="item.code" v-if="item.childrens.length>0&&item.childrens[0].category!=2">
                <template slot="title">
                    <span><i :class="item.source"></i>{{item.name}}</span>
                </template>
                <el-menu-item :index="subItem.code" v-for="(subItem,subIdx) in item.childrens" :key="subItem.code"   @click="clickItem(subItem)">{{subItem.name}}</el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.code" v-else><i :class="item.source"></i>{{item.name}}</el-menu-item>
<!--            <el-menu-item index="boothList" v-else-if="item.code=='boothList'"><i :class="item.source"></i>摊位信息查询</el-menu-item>-->
        </el-menu>
    </div>
</template>

<script>

import subMenu from '@/components/subMenu'
import {mapGetters,mapActions} from  'vuex'
import {mixin} from '../mixin'
import Http from "@/service/http";
  export default {
    name:'SideBar',
    mixins: [mixin],
    props:{
      matched: {
        default: () => {
          return []
        }
      },

    },
    data(){
      return{
      }
    },
    components: {
      subMenu,
    },
    computed: {
      // menuList(){
      //   return menuList
      //   console.log(this.$route)
      // },
      defaultActive(){
        let len=this.matched.length
        let highLight=this.matched[len-1].meta.highLight
        console.log(highLight||this.matched[len-1].name)
        return  highLight||this.matched[len-1].name
      },

      ...mapGetters(['menuList']),

    },
    methods: {
      ...mapActions(['setPowerRoutes','setButton','setMenu']),
      handleOpen(key, keyPath) {
      },
      handleClose(){},
      handleSelect(name){
        this.$router.push({name})
      },
      clickItem(obj){
        // console.log(obj)
        this.setButton(obj.buttonIdCn)
        // console.log(obj.menu_id)
      },

    },
    created() {
    },
    watch:{
      '$route'(to, from) {
        // this.setButton(to.meta.button_id_cn)
      }

    }

  }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        transition: width 0.28s;
        flex: 0 0 256px;
        /*width: 256px;*/
        display: flex;
        flex-direction: column;
        /*flex:1;*/
        background-color: #20222A;
        /*height: calc(100vh - 60px);*/
        /*position: fixed;*/
        font-size: 0px;
        /*top: 60px;*/
        /*bottom: 0;*/
        /*left: 0;*/
        /*z-index: 1001;*/
        overflow: auto;
        /*display: inline-block;*/
        .el-menu-vertical-demo{
            border-right-color:#fff;
        }
        .el-menu{
            border-right-color: #20222A;
        }
    }
</style>
