<template >
    <subMenu :index="data.path">
        <template slot="title">
            <i :class="data.icon"></i>
            <span>{{data.name}}</span>
        </template>
        <template v-for="item in data.children">
            <router-link :to="{name:item.path}" :key="item.path" v-if="!item.children">
                <el-menu-item class="subitem" :index="item.path">
                    <i :class="item.icon"></i>
                    <span slot="title">{{item.name}}</span>
                </el-menu-item>
            </router-link>
            <subMenu v-else :data="item" :key="item.path"></subMenu>
        </template>
    </subMenu>
</template>

<script>


  export default {
    name:'subMenu',
    props: {
      data: ''
    },
    data(){
      return{
      }
    },
    created(){
    },
    components: {
    },
    computed: {

    },
    methods: {
      handleOpen(){},
      handleClose(){},
    }
  }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        transition: width 0.28s;
        width: 210px !important;
        background-color: #fff;
        height: 100%;
        position: fixed;
        font-size: 0px;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;
        overflow: hidden;
    }
</style>
