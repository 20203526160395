<template>
    <div class="main-box">
        <SideBar :matched="matched"></SideBar>
        <div class="main-container">
            <div class="navbar">
                <Breadcrumb :data="data"></Breadcrumb>
            </div>
            <div class="app-main">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import Breadcrumb from '@/components/Breadcrumb'
import {mapGetters,mapActions} from  'vuex'
  export default {
    name: 'Login',
    data() {
      return {
        data:[]
      }
    },
    created() {
    },
    computed:{
      ...mapGetters(['menuList']),
      matched(){
        return this.$route.matched
      }


    },
    watch: {

    },
    components:{SideBar,Breadcrumb},
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
    .main-box{
        height:calc(100vh - 60px);
        display: flex;
    }

    .main-container {
        flex:1;
        height: calc(100vh - 100px);
        -webkit-transition: margin-left .28s;
        background-color: #F0F2F5;
        padding:20px;
        overflow-x: hidden;
        .navbar {
            margin-bottom: 20px;
            height: 50px;
            overflow: hidden;
            position: relative;
            background:#fff;
            -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
            box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 20px;
            left:0;
            right:0;
        }

        .app-main {
            min-height:calc(100vh);
            overflow: auto;
            padding: 20px;
            background-color: #fff;

        }


    }
</style>
