<template>
    <div class="bread">
        <i :class="is_collapse?'el-icon-s-fold':'el-icon-s-unfold'"></i>
        <el-breadcrumb>
            <el-breadcrumb-item v-for="(item,index) in bread_data" :key="index" :to="{name:item.name}">
                {{item.meta.menu_name}}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import {mixin} from '../mixin'
  export default {
    mixins: [mixin],
    props: {
      data: null
    },
    data() {
      return {
        levelList: null,
        isCollapse: false
      }
    },
    computed: {
      bread_data() {
        let arr=this.$route.matched.slice(2)
        let highLight=arr[arr.length-1].meta.highLight
        let parent_menu=arr[arr.length-1].meta.parent_menu
        let parent_route=arr[arr.length-1].meta.parent_route
        if(highLight)
          arr.splice(1,0,{
            name:parent_route,
            meta:{
              menu_name:parent_menu
            }          })
        return arr
      },
      ...mapGetters(['is_collapse'])

    },
    components: {},
    watch: {},
    created() {
    },
    methods: {
      ...mapActions(['setIsCollapse']),
      turnMenu() {
        if (this.is_collapse) {
          document.getElementsByClassName('sidebar-container')[1].style.width = '256px'
          document.getElementsByClassName('main-container')[0].style.marginLeft = '256px'
        } else {
          document.getElementsByClassName('sidebar-container')[1].style.width = '0'
          document.getElementsByClassName('main-container')[0].style.marginLeft = '0'
        }

        this.setIsCollapse(!this.is_collapse)

      }
    }
  }
</script>

<style lang="scss" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 8px;

        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }

    .bread {
        display: flex;
        align-items: center;

        i {
            margin-right: 10px;
            color: #333;
            font-size: 20px;
            display: inline-block;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;

            &:hover {
                background: #f0f0f0;
            }
        }

    }
</style>
